import Api from "../../Shared/services/api";
import { buildParams } from "../../../plugins/helpers";

const baseUrl = "DownloadCenter";
export default {
  query(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(`${baseUrl}?` + qParams.toString());
  },
  queryManagement(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(`${baseUrl}/Management?` + qParams.toString());
  },
};
