import perms from "../../../plugins/permissions";
export default [
  {
    path: "/clients",
    name: "clients",
    meta: { layout: "dashboard", title: "Clients", permissions: [perms.Clients.View] },
    component: () => import("./views/Clients.vue"),
  },
  {
    path: "/restore-clients",
    name: "restore-clients",
    meta: { layout: "dashboard", title: "Restore Clients", permissions: [perms.Clients.Restore] },
    component: () => import("./views/ClientsRestore.vue"),
  },
  {
    path: "/clients/:id",
    name: "single-client",
    meta: { layout: "dashboard", permissions: [perms.Clients.View] },
    component: () => import("./views/Clients.vue"),
  },
];
