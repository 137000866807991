import perms from "../../../plugins/permissions";
export default [
  {
    path: "/architects",
    name: "architects",
    // TODO: update this line when permissions updated
    meta: { layout: "dashboard", title: "Architects", permissions: [perms.Architects.View] },
    component: () => import("./views/Architects.vue"),
  },
  {
    path: "/architects/:id",
    name: "single-architect",
    meta: { layout: "dashboard", permissions: [perms.Architects.View] },
    component: () => import("./views/Architects.vue"),
  },
];
