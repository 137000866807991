let permissions = {
  Dashboard: {
    ViewAllWidgets: "Dashboard.ViewAllWidgets",
  },
  DNAUsers: {
    Create: "DNAUsers.Create",
    View: "DNAUsers.View",
    Update: "DNAUsers.Update",
    Suspend: "DNAUsers.Suspend",
    Unsuspend: "DNAUsers.Unsuspend",
    ViewDefaultLaborRates: "DNAUsers.ViewDefaultLaborRates",
    UpdateDefaultLaborRates: "DNAUsers.UpdateDefaultLaborRates",
  },
  ExternalUsers: {
    Create: "ExternalUsers.Create",
    View: "ExternalUsers.View",
    Update: "ExternalUsers.Update",
    Delete: "ExternalUsers.Delete",
  },
  Invitations: {
    Create: "Invitations.Create",
    View: "Invitations.View",
    Update: "Invitations.Update",
    Delete: "Invitations.Delete",
  },
  CompanyContacts: {
    Create: "CompanyContacts.Create",
    View: "CompanyContacts.View",
    Update: "CompanyContacts.Update",
    Invite: "CompanyContacts.Invite",
    Delete: "CompanyContacts.Delete",
  },
  Architects: {
    Create: "Architects.Create",
    View: "Architects.View",
    Update: "Architects.Update",
    Delete: "Architects.Delete",
  },
  Clients: {
    Create: "Clients.Create",
    View: "Clients.View",
    Update: "Clients.Update",
    Delete: "Clients.Delete",
    Restore: "Clients.Restore",
  },
  EngineeringFirms: {
    Create: "EngineeringFirms.Create",
    View: "EngineeringFirms.View",
    Update: "EngineeringFirms.Update",
    Delete: "EngineeringFirms.Delete",
  },
  GeneralContractors: {
    Create: "GeneralContractors.Create",
    View: "GeneralContractors.View",
    Update: "GeneralContractors.Update",
    Delete: "GeneralContractors.Delete",
  },
  Manufacturers: {
    Create: "Manufacturers.Create",
    View: "Manufacturers.View",
    Update: "Manufacturers.Update",
    Delete: "Manufacturers.Delete",
  },
  Representatives: {
    Create: "Representatives.Create",
    View: "Representatives.View",
    Update: "Representatives.Update",
    Delete: "Representatives.Delete",
  },
  SubContractors: {
    Create: "SubContractors.Create",
    View: "SubContractors.View",
    Update: "SubContractors.Update",
    Delete: "SubContractors.Delete",
  },
  SystemsIntegrators: {
    Create: "SystemsIntegrators.Create",
    View: "SystemsIntegrators.View",
    Update: "SystemsIntegrators.Update",
    Delete: "SystemsIntegrators.Delete",
  },
  AppSettings: {
    Create: "AppSettings.Create",
    View: "AppSettings.View",
    Update: "AppSettings.Update",
    Delete: "AppSettings.Delete",
  },
  AuditLog: {
    View: "AuditLog.View",
  },
  Projects: {
    Create: "Projects.Create",
    View: "Projects.View",
    Update: "Projects.Update",
    Delete: "Projects.Delete",
    UpdateProjectNumber: "Projects.UpdateProjectNumber",
    CreateJiraProject: "Projects.CreateJiraProject",
    CreateMsTeam: "Projects.CreateMsTeam",
    Restore: "Projects.Restore",
  },
  SpaceTemplates: {
    Create: "SpaceTemplates.Create",
    View: "SpaceTemplates.View",
    Update: "SpaceTemplates.Update",
    Delete: "SpaceTemplates.Delete",
    Restore: "SpaceTemplates.Restore",
  },
  Equipments: {
    Create: "Equipments.Create",
    View: "Equipments.View",
    Update: "Equipments.Update",
    BulkUpdate: "Equipments.BulkUpdate",
    Delete: "Equipments.Delete",
    Restore: "Equipments.Restore",
  },
  EquipmentCategories: {
    Create: "EquipmentCategories.Create",
    View: "EquipmentCategories.View",
    Update: "EquipmentCategories.Update",
    Delete: "EquipmentCategories.Delete",
  },
  ProjectReports: {
    Create: "ProjectReports.Create",
  },
  Roles: {
    Create: "Roles.Create",
    View: "Roles.View",
    Update: "Roles.Update",
    Delete: "Roles.Delete",
  },
  ExternalRoles: {
    Create: "ExternalRoles.Create",
    View: "ExternalRoles.View",
    Update: "ExternalRoles.Update",
    Delete: "ExternalRoles.Delete",
  },
  Leads: {
    Create: "Leads.Create",
    View: "Leads.View",
    Update: "Leads.Update",
    Delete: "Leads.Delete",
    Archive: "Leads.Archive",
    Unarchive: "Leads.Unarchive",
  },
  DrawingDocuments: {
    Create: "DrawingDocuments.Create",
    View: "DrawingDocuments.View",
    Update: "DrawingDocuments.Update",
    Delete: "DrawingDocuments.Delete",
  },
  LayoutTemplates: {
    Create: "LayoutTemplates.Create",
    View: "LayoutTemplates.View",
    Update: "LayoutTemplates.Update",
    Delete: "LayoutTemplates.Delete",
  },
  LayoutViews: {
    Create: "LayoutViews.Create",
    View: "LayoutViews.View",
    Update: "LayoutViews.Update",
    Delete: "LayoutViews.Delete",
  },
  NamingTemplates: {
    Create: "NamingTemplates.Create",
    View: "NamingTemplates.View",
    Update: "NamingTemplates.Update",
    Delete: "NamingTemplates.Delete",
  },
  ProjectVariables: {
    View: "ProjectVariables.View",
    Update: "ProjectVariables.Update",
  },
  ProjectEquipment: {
    View: "ProjectEquipment.View",
    Update: "ProjectEquipment.Update",
  },
  ProjectBudget: {
    View: "ProjectBudget.View",
    Update: "ProjectBudget.Update",
  },
  ProjectTimeline: {
    View: "ProjectTimeline.View",
    Update: "ProjectTimeline.Update",
  },
  // ProjectAssignees: {
  //   View: "ProjectAssignees.View",
  //   Update: "ProjectAssignees.Update",
  // },
  // ProjectPhase: {
  //   AuthorizedFees: "ProjectPhase.AuthorizedFees",
  // },
  ProjectContacts: {
    View: "ProjectContacts.View",
    Update: "ProjectContacts.Update",
  },
  BigBoard: {
    View: "BigBoard.View",
    ViewEntryDetails: "BigBoard.ViewEntryDetails",
    /////
    ViewLaborHoursSummary: "BigBoard.ViewLaborHoursSummary",
    ViewLaborRevenueSummary: "BigBoard.ViewLaborRevenueSummary",
    ViewLaborCostSummary: "BigBoard.ViewLaborCostSummary",
    ViewDirectExpensesSummary: "BigBoard.ViewDirectExpensesSummary",
    ViewDirectExpensesCostSummary: "BigBoard.ViewDirectExpensesCostSummary",
    ViewDirectExpensesRevenueSummary: "BigBoard.ViewDirectExpensesRevenueSummary",
    ViewNetSummary: "BigBoard.ViewNetSummary",
    ViewNetSummaryTotalCost: "BigBoard.ViewNetSummaryTotalCost",
    ViewNetSummaryTotalRevenue: "BigBoard.ViewNetSummaryTotalRevenue",
    ViewNetSummaryNetProfit: "BigBoard.ViewNetSummaryNetProfit",
    /////
    ViewLaborTypeAssociateConsultants: "BigBoard.ViewLaborTypeAssociateConsultants",
    ViewLaborTypeSeniorConsultants: "BigBoard.ViewLaborTypeSeniorConsultants",
    ViewLaborTypeConsultants: "BigBoard.ViewLaborTypeConsultants",
    ViewLaborTypeVirtualIntegrators: "BigBoard.ViewLaborTypeVirtualIntegrators",
    ViewLaborTypeBIMSpecialists: "BigBoard.ViewLaborTypeBIMSpecialists",
    ViewLaborTypeManagement: "BigBoard.ViewLaborTypeManagement",
    ViewLaborTypeTechnical: "BigBoard.ViewLaborTypeTechnical",
    ViewLaborTypeSCD: "BigBoard.ViewLaborTypeSCD",
  },
  SmallBoard: {
    View: "SmallBoard.View",
    ViewEntryDetails: "SmallBoard.ViewEntryDetails",
    /////
    ViewLaborHoursSummary: "SmallBoard.ViewLaborHoursSummary",
    ViewLaborRevenueSummary: "SmallBoard.ViewLaborRevenueSummary",
    ViewLaborCostSummary: "SmallBoard.ViewLaborCostSummary",
    ViewDirectExpensesSummary: "SmallBoard.ViewDirectExpensesSummary",
    ViewDirectExpensesCostSummary: "SmallBoard.ViewDirectExpensesCostSummary",
    ViewDirectExpensesRevenueSummary: "SmallBoard.ViewDirectExpensesRevenueSummary",
    ViewNetSummary: "SmallBoard.ViewNetSummary",
    ViewNetSummaryTotalCost: "SmallBoard.ViewNetSummaryTotalCost",
    ViewNetSummaryTotalRevenue: "SmallBoard.ViewNetSummaryTotalRevenue",
    ViewNetSummaryNetProfit: "SmallBoard.ViewNetSummaryNetProfit",
    /////
    ViewLaborTypeAssociateConsultants: "SmallBoard.ViewLaborTypeAssociateConsultants",
    ViewLaborTypeSeniorConsultants: "SmallBoard.ViewLaborTypeSeniorConsultants",
    ViewLaborTypeConsultants: "SmallBoard.ViewLaborTypeConsultants",
    ViewLaborTypeVirtualIntegrators: "SmallBoard.ViewLaborTypeVirtualIntegrators",
    ViewLaborTypeBIMSpecialists: "SmallBoard.ViewLaborTypeBIMSpecialists",
    ViewLaborTypeManagement: "SmallBoard.ViewLaborTypeManagement",
    ViewLaborTypeTechnical: "SmallBoard.ViewLaborTypeTechnical",
    ViewLaborTypeSCD: "SmallBoard.ViewLaborTypeSCD",
  },
  MyBoard: {
    View: "MyBoard.View",
    ViewEntryDetails: "MyBoard.ViewEntryDetails",
    /////
    ViewLaborHoursSummary: "MyBoard.ViewLaborHoursSummary",
    ViewLaborRevenueSummary: "MyBoard.ViewLaborRevenueSummary",
    ViewLaborCostSummary: "MyBoard.ViewLaborCostSummary",
    ViewDirectExpensesSummary: "MyBoard.ViewDirectExpensesSummary",
    ViewDirectExpensesCostSummary: "MyBoard.ViewDirectExpensesCostSummary",
    ViewDirectExpensesRevenueSummary: "MyBoard.ViewDirectExpensesRevenueSummary",
    ViewNetSummary: "MyBoard.ViewNetSummary",
    ViewNetSummaryTotalCost: "MyBoard.ViewNetSummaryTotalCost",
    ViewNetSummaryTotalRevenue: "MyBoard.ViewNetSummaryTotalRevenue",
    ViewNetSummaryNetProfit: "MyBoard.ViewNetSummaryNetProfit",
    /////
    ViewLaborTypeAssociateConsultants: "MyBoard.ViewLaborTypeAssociateConsultants",
    ViewLaborTypeSeniorConsultants: "MyBoard.ViewLaborTypeSeniorConsultants",
    ViewLaborTypeConsultants: "MyBoard.ViewLaborTypeConsultants",
    ViewLaborTypeVirtualIntegrators: "MyBoard.ViewLaborTypeVirtualIntegrators",
    ViewLaborTypeBIMSpecialists: "MyBoard.ViewLaborTypeBIMSpecialists",
    ViewLaborTypeManagement: "MyBoard.ViewLaborTypeManagement",
    ViewLaborTypeTechnical: "MyBoard.ViewLaborTypeTechnical",
    ViewLaborTypeSCD: "MyBoard.ViewLaborTypeSCD",
  },
  DNADefaults: {
    View: "DNADefaults.View",
    ViewLaborRates: "DNADefaults.ViewLaborRates",
    UpdateLaborRates: "DNADefaults.UpdateLaborRates",
    ViewAccessGroups: "DNADefaults.ViewAccessGroups",
    UpdateAccessGroups: "DNADefaults.UpdateAccessGroups",
  },
  PersonalSettings: {
    View: "PersonalSettings.View",
    Update: "PersonalSettings.Update",
    ShowManagementSettings: "PersonalSettings.ShowManagementSettings",
  },
  ProjectContracts: {
    Create: "ProjectContracts.Create",
    View: "ProjectContracts.View",
    Update: "ProjectContracts.Update",
    Delete: "ProjectContracts.Delete",
    ///////////////
    Approve: "ProjectContracts.Approve",
    Unapprove: "ProjectContracts.Unapprove",
    Lock: "ProjectContracts.Lock",
    Unlock: "ProjectContracts.Unlock",
    SendForApproval: "ProjectContracts.SendForApproval",
    SendForUpdate: "ProjectContracts.SendForUpdate",
    Manage: "ProjectContracts.Manage",
    ///////////////
    Archive: "ProjectContracts.Archive",
    Unarchive: "ProjectContracts.Unarchive",
    ///////////////
    ConstructionPhasesView: "ProjectContracts.ConstructionPhasesView",
    ConstructionPhasesCreate: "ProjectContracts.ConstructionPhasesCreate",
    ConstructionPhasesUpdate: "ProjectContracts.ConstructionPhasesUpdate",
    ConstructionPhasesDelete: "ProjectContracts.ConstructionPhasesDelete",
    ///////////////
    DocumentsView: "ProjectContracts.DocumentsView",
    DocumentsUpload: "ProjectContracts.DocumentsUpload",
    DocumentsUpdateDetails: "ProjectContracts.DocumentsUpdateDetails",
    DocumentsDownload: "ProjectContracts.DocumentsDownload",
    DocumentsDelete: "ProjectContracts.DocumentsDelete",
    ///////////////
    ViewLaborSellRates: "ProjectContracts.ViewLaborSellRates",
    UpdateLaborSellRates: "ProjectContracts.UpdateLaborSellRates",
    ViewLaborCostRates: "ProjectContracts.ViewLaborCostRates",
    UpdateLaborCostRates: "ProjectContracts.UpdateLaborCostRates",
    ViewAndUpdateLaborRatesGAFees: "ProjectContracts.ViewAndUpdateLaborRatesGAFees",
    ViewAndUpdateDirectExpensesCost: "ProjectContracts.ViewAndUpdateDirectExpensesCost",
    ViewRevenueSums: "ProjectContracts.ViewRevenueSums",
    ViewCostSums: "ProjectContracts.ViewCostSums",
  },
  ProjectAccessList: {
    Create: "ProjectAccessList.Create",
    View: "ProjectAccessList.View",
    Update: "ProjectAccessList.Update",
    Delete: "ProjectAccessList.Delete",
  },
  AccessGroups: {
    Create: "AccessGroups.Create",
    View: "AccessGroups.View",
    Update: "AccessGroups.Update",
    Delete: "AccessGroups.Delete",
  },
  ExternalAccessGroups: {
    Create: "ExternalAccessGroups.Create",
    View: "ExternalAccessGroups.View",
    Update: "ExternalAccessGroups.Update",
    Delete: "ExternalAccessGroups.Delete",
  },
  ProjectActivityLogs: {
    View: "ProjectActivityLogs.View",
  },
  ProjectNotes: {
    Create: "ProjectNotes.Create",
    View: "ProjectNotes.View",
    Update: "ProjectNotes.Update",
    Delete: "ProjectNotes.Delete",
  },
  ExternalDashboard: {
    ViewAllWidgets: "ViewAllWidgets",
  },
  ExternalProjects: {
    View: "ExternalProjects.View",
  },
  ExternalEquipment: {
    View: "ExternalEquipment.View",
  },
  ProjectExternalAccessList: {
    Create: "ProjectExternalAccessList.Create",
    View: "ProjectExternalAccessList.View",
    Update: "ProjectExternalAccessList.Update",
    Delete: "ProjectExternalAccessList.Delete",
  },
  ResourceOffers: {
    Create: "ResourceOffers.Create",
    View: "ResourceOffers.View",
    Update: "ResourceOffers.Update",
    Delete: "ResourceOffers.Delete",
    Accept: "ResourceOffers.Accept",
    Decline: "ResourceOffers.Decline",
    Manage: "ResourceOffers.Manage",
    CreateForOthers: "ResourceOffers.CreateForOthers",
    Approve: "ResourceOffers.Approve",
    Reject: "ResourceOffers.Reject",
  },
  ResourceShiftRequests: {
    Create: "ResourceShiftRequests.Create",
    View: "ResourceShiftRequests.View",
    Update: "ResourceShiftRequests.Update",
    Delete: "ResourceShiftRequests.Delete",
    Manage: "ResourceShiftRequests.Manage",
    CreateForOthers: "ResourceShiftRequests.CreateForOthers",
    Approve: "ResourceShiftRequests.Approve",
    Reject: "ResourceShiftRequests.Reject",
  },
  DownloadCenter: {
    View: "DownloadCenter.View",
    Manage: "DownloadCenter.Manage",
  },
};
export default permissions;
