<template>
  <v-row class="my-0">
    <v-col cols="12" md="6" sm="12" class="alt-comp-wrapper">
      <v-autocomplete-alt
        auto-select-first
        style="width: 100%"
        :rules="[allRules.required]"
        label="Manufacturer"
        id="equipmentManufacturer"
        placeholder="Choose a Manufacturer"
        v-model="selected.manufacturerId"
        @input="manufacturerChanged"
        :items="manufacturers"
        :filter="manufacturersFilter"
        dense
        filled
        clearable
        item-text="name"
        item-value="id"
        :loading="isManufacturersLoading"
        :disabled="isManufacturersLoading"
        :readonly="readonly"
      >
        <template v-slot:selection="{ item }">
          <div class="d-flex align-center" style="gap: 0.5rem">
            <v-tooltip right z-index="999" nudge-right="-4px">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <div class="company-logo-mini">
                    <div
                      style="height: 100%; width: 100%"
                      v-viewer
                      @click.stop
                      v-if="item.logoUrl != null && item.logoUrl != ''"
                    >
                      <img
                        :key="item.id + '_img'"
                        :src="item.logoUrl"
                        height="100%"
                        width="100%"
                        style="object-fit: contain"
                      />
                    </div>
                    <div style="height: 100%; width: 100%" v-viewer @click.stop v-else>
                      <img
                        :key="item.id + '_img'"
                        src="/img/DNA_Symbol.png"
                        height="100%"
                        width="100%"
                        style="object-fit: contain"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <span>
                <v-img
                  v-if="item.logoUrl != null && item.logoUrl != ''"
                  :src="item.logoUrl"
                  height="250px"
                  width="250px"
                  contain
                ></v-img>
                <v-img
                  v-else
                  src="/img/DNA_Symbol.png"
                  height="250px"
                  width="250px"
                  contain
                ></v-img>
              </span>
            </v-tooltip>
            <h5 class="ma-0 ml-2">{{ item.name }}</h5>
          </div>
        </template>
        <template v-slot:item="{ item }">
          <div class="d-flex align-center" style="gap: 0.5rem">
            <v-tooltip right z-index="999" nudge-right="-4px">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <div class="company-logo-mini">
                    <div
                      style="height: 100%; width: 100%"
                      v-viewer
                      @click.stop
                      v-if="item.logoUrl != null && item.logoUrl != ''"
                    >
                      <img
                        :key="item.id + '_img'"
                        :src="item.logoUrl"
                        height="100%"
                        width="100%"
                        style="object-fit: contain"
                      />
                    </div>
                    <div style="height: 100%; width: 100%" v-viewer @click.stop v-else>
                      <img
                        :key="item.id + '_img'"
                        src="/img/DNA_Symbol.png"
                        height="100%"
                        width="100%"
                        style="object-fit: contain"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <span>
                <v-img
                  v-if="item.logoUrl != null && item.logoUrl != ''"
                  :src="item.logoUrl"
                  height="250px"
                  width="250px"
                  contain
                ></v-img>
                <v-img
                  v-else
                  src="/img/DNA_Symbol.png"
                  height="250px"
                  width="250px"
                  contain
                ></v-img>
              </span>
            </v-tooltip>
            <h5 class="ma-0 ml-2">{{ item.name }}</h5>
          </div>
        </template>
      </v-autocomplete-alt>
      <v-btn
        min-width="28px !important"
        width="28px !important"
        height="28px !important"
        color="secondary"
        outlined
        class="pa-0"
        small
        :loading="isManufacturersLoading"
        :disabled="isManufacturersLoading || readonly"
        @click="getManufacturers"
      >
        <i class="fas fa-sync"></i>
      </v-btn>
      <v-btn
        min-width="28px !important"
        width="28px !important"
        height="28px !important"
        color="secondary"
        class="pa-0"
        small
        link
        to="/manufacturers/create"
        target="_blank"
      >
        <i class="fal fa-plus"></i>
      </v-btn>
    </v-col>
    <v-col cols="12" md="6" sm="12" class="alt-comp-wrapper">
      <v-autocomplete-alt
        auto-select-first
        style="width: 100%"
        :rules="[allRules.required]"
        label="Product Family"
        id="equipmentProductFamily"
        placeholder="Choose a Product Family"
        v-model="selected.productFamilyId"
        :items="productFamilies"
        dense
        filled
        item-text="name"
        item-value="id"
        :loading="isProductFamiliesLoading"
        :disabled="isProductFamiliesLoading || selected.manufacturerId == null"
        :readonly="readonly"
      >
      </v-autocomplete-alt>
      <v-btn
        min-width="28px !important"
        width="28px !important"
        height="28px !important"
        color="secondary"
        class="pa-0"
        small
        @click="createProductFamily()"
        v-if="!readonly"
        :disabled="isManufacturersLoading || selected.manufacturerId == null || disableAddFamily"
      >
        <i class="fal fa-plus"></i>
      </v-btn>
    </v-col>
    <edit-product-family
      :manufacturer-id="selected.manufacturerId"
      ref="editProductFamily"
      nested
      @save="onProductFamilySaved"
      @close="onProductFamilyClosed"
    ></edit-product-family>
  </v-row>
</template>

<script>
import EditProductFamily from "../../Companies/Manufacturers/components/EditProductFamily.vue";
import manufacturersAPI from "../../Companies/Manufacturers/services/manufacturers-service.js";
import productFamiliesAPI from "../../Companies/Manufacturers/services/productFamilies-service";

export default {
  components: { EditProductFamily },
  data() {
    return {
      isManufacturersLoading: true,
      manufacturer: null,
      manufacturers: [],
      isProductFamiliesLoading: false,
      productFamily: null,
      productFamilies: [],
      selected: {
        manufacturerId: null,
        productFamilyId: null,
      },
    };
  },
  props: {
    manufacturerId: {
      type: Number,
      default: null,
    },
    productFamilyId: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    disableAddFamily() {
      if (this.manufacturer && this.manufacturer.name == "**NO ASSOCIATED MAKE**") {
        return true;
      }
      return false;
    },
  },
  methods: {
    createProductFamily() {
      this.$refs.editProductFamily.open(null);
    },
    onProductFamilyClosed() {},
    onProductFamilySaved(productFamilyData, isNewProductFamily) {
      this.$log("onProductFamilySaved", productFamilyData, isNewProductFamily);
      if (isNewProductFamily) {
        this.addToArr(this.productFamilies, productFamilyData);
      } else {
        this.updateArr(this.productFamilies, productFamilyData);
      }
      this.selected.productFamilyId = productFamilyData.id;
    },
    getManufacturers() {
      this.isManufacturersLoading = true;
      manufacturersAPI
        .queryLite({ itemsPerPage: -1 })
        .then((resp) => {
          this.manufacturers = resp.data.items;
          this.updateManufacturerObj();
          this.isManufacturersLoading = false;
        })
        .catch((err) => {
          this.isManufacturersLoading = false;
          this.$handleError(err);
        });
    },
    manufacturersFilter(item, queryText, itemText) {
      const text1 = item.name.toLowerCase();
      const text2 = item.abbreviatedName.toLowerCase();
      const searchText = queryText.toLowerCase();

      return text1.indexOf(searchText) > -1 || text2.indexOf(searchText) > -1;
    },
    updateManufacturerObj() {
      if (this.selected.manufacturerId != null && this.manufacturers.length > 0) {
        this.manufacturer = this.manufacturers.find((b) => b.id == this.selected.manufacturerId);
      } else {
        this.manufacturer = null;
      }
    },
    updateProductFamilyObj() {
      if (this.selected.productFamilyId != null && this.productFamilies.length > 0) {
        this.productFamily = this.productFamilies.find(
          (b) => b.id == this.selected.productFamilyId
        );
      }
    },
    manufacturerChanged(val) {
      this.updateManufacturerObj();
    },
    annouceUpdate() {
      // this.$log(">>>>>>>>> ANNOUNCE", this.selected.level);
      var toAnnounce = this.cloneDeep(this.selected.level);
      if (this.selected.level != null) toAnnounce.building = null;
      this.$emit("input", toAnnounce);
    },
    getProductFamilies() {
      if (this.selected.manufacturerId == null) return;
      this.isProductFamiliesLoading = true;
      this.productFamilies = [];
      productFamiliesAPI
        .query({
          itemsPerPage: -1,
          manufacturerId: this.selected.manufacturerId,
        })
        .then((resp) => {
          this.isProductFamiliesLoading = false;
          this.productFamilies = resp.data.items;
          this.productFamilies.unshift({
            id: -1,
            name: "Product Family not applicable",
            description: "Product Family not applicable",
          });
          this.updateProductFamilyObj();
        })
        .catch((err) => {
          this.isProductFamiliesLoading = false;
          this.$handleError(err);
        });
    },
  },
  mounted() {
    this.selected.manufacturerId = this.manufacturerId;
    this.selected.productFamilyId = this.productFamilyId || -1;
    this.getManufacturers();
    this.getProductFamilies();
  },
  watch: {
    manufacturerId: {
      handler() {
        if (this.selected.manufacturerId != this.manufacturerId) {
          this.selected.manufacturerId = this.manufacturerId;
          this.updateManufacturerObj();
        }
      },
    },
    productFamilyId: {
      handler() {
        //both are equal then no need to copy
        if (
          (this.selected.productFamilyId == -1 && this.productFamilyId == null) ||
          this.selected.productFamilyId == this.productFamilyId
        )
          return;

        this.selected.productFamilyId = this.productFamilyId == null ? -1 : this.productFamilyId;
        this.getProductFamilies();
      },
    },
    "selected.manufacturerId": {
      handler() {
        if (this.selected.manufacturerId != this.manufacturerId) {
          this.getProductFamilies();
          this.selected.productFamilyId = -1;
          this.$emit("update:manufacturerId", this.selected.manufacturerId);
        }
      },
    },
    "selected.productFamilyId": {
      handler() {
        //both are equal then no need to announce
        if (
          (this.selected.productFamilyId == -1 && this.productFamilyId == null) ||
          this.selected.productFamilyId == this.productFamilyId
        )
          return;

        if (this.selected.productFamilyId == -1) this.$emit("update:productFamilyId", null);
        else this.$emit("update:productFamilyId", this.selected.productFamilyId);
        this.updateProductFamilyObj();
      },
    },
  },
};
</script>

<style lang="scss">
.alt-comp-wrapper {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
