<template>
  <v-menu
    v-model="downloadCenterPopover"
    :close-on-content-click="false"
    :nudge-width="300"
    offset-y
    bottom
    left
    content-class="download-center-menu-content"
  >
    <template v-slot:activator="{ attrs: menuAttrs, on: menuOn }">
      <v-tooltip bottom z-index="999" nudge-bottom="-4px">
        <template v-slot:activator="{ on, attrs }">
          <div color="info" v-bind="menuAttrs" v-on="menuOn">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              small
              elevation="0"
              class="d-flex align-center justify-center"
            >
              <v-badge
                bordered
                bottom
                color="pink"
                offset-y="0px"
                dot
                :value="downloadCenterBadge"
                class="d-flex"
              >
                <i v-if="downloadCenterBadge" class="fad fa-down-to-bracket pink--text fs-16px"></i>
                <i v-else class="far fa-down-to-bracket fs-16px secondary--text opacity-64"></i>
              </v-badge>
            </v-btn>
          </div>
        </template>
        Download Center
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title
        class="fs-14px pa-0 white flex-column justify-space-between"
        style="position: sticky; top: 0; z-index: 10000"
      >
        <div class="d-flex flex-row px-2 py-1" style="width: 100%">
          <router-link
            v-if="$has(perms.DownloadCenter.View)"
            :to="'/download-center'"
            class="secondary--text opacity-87 pl-1"
            style="font-weight: 600; text-decoration: none"
          >
            <i class="fad fa-down-to-bracket mr-2"></i>Download Center
          </router-link>
          <div v-else class="secondary--text opacity-87 pl-1" style="font-weight: 600">
            <i class="fad fa-down-to-bracket mr-2"></i>Download Center
          </div>
          <v-spacer></v-spacer>
          <v-btn :color="'secondary'" :disabled="isDownloadsLoading" small icon @click="refresh">
            <i class="far fa-refresh fs-14px" :class="isDownloadsLoading ? 'fa-spin' : ''"></i>
          </v-btn>
          <v-progress-linear
            color="blue"
            absolute
            indeterminate
            bottom
            :active="isDownloadsLoading"
          ></v-progress-linear>
        </div>
        <v-divider style="width: 100%"></v-divider>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-scroll-y-transition mode="out-in">
          <div
            key="loading-downloads"
            v-if="downloads.length == 0 && isDownloadsLoading"
            class="py-6 d-flex flex-column align-center justify-center"
          >
            <video
              width="250"
              muted
              loop
              autoplay
              class="inset-shadow-video"
              style="padding: 0.5rem; border-radius: 10rem; background: #fff"
            >
              <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
            </video>
            <h3 class="mt-3">Loading your recent downloads...</h3>
          </div>
          <div
            key="loading-empty"
            v-else-if="downloads.length == 0 && !isDownloadsLoading"
            class="py-6 d-flex flex-column align-center justify-center"
          >
            <img width="250" src="/img/art/fogg-206.png" />
            <!-- <i class="fa-duotone fa-face-sunglasses fs-28px mb-3"></i> -->
            <h3>It's empty in here!</h3>
          </div>
          <div v-else key="results">
            <div class="download-card" v-for="(downloadEntry, index) in downloads" :key="index">
              <h5 class="d-flex justify-space-between pr-1" style="width: 100%">
                <span>
                  <i class="fad mr-1" :class="getTemplateType(downloadEntry).icon"></i>
                  {{ getTemplateType(downloadEntry).text }}
                </span>
                <dater :date="downloadEntry.createDate" no-tooltip></dater>
              </h5>
              <h4 class="secondary--text">
                {{ downloadEntry.fileName }}
              </h4>
              <h4 class="d-flex align-center justify-space-between" style="width: 100%">
                <!-- <span>
                  <i
                    class="fad mr-1"
                    :class="[
                      getDownloadStatus(downloadEntry).icon,
                      `${getDownloadStatus(downloadEntry).color}--text`,
                    ]"
                  ></i
                  ><u :class="[`${getDownloadStatus(downloadEntry).color}--text`]">{{
                    getDownloadStatus(downloadEntry).text
                  }}</u>
                </span> -->
                <download-status :status="downloadEntry.status"></download-status>
                <v-btn
                  x-small
                  class="align-self-end mt-1"
                  color="green"
                  style="margin-bottom: 0.5rem; color: #fff"
                  :href="downloadEntry.url"
                  :download="downloadEntry.fileName"
                  :disabled="downloadEntry.url == null || downloadEntry.url == ''"
                >
                  <span class="fs-10px">
                    <i class="fas fa-arrow-down mr-2"></i>
                    <span style="font-weight: 600">Download</span>
                  </span>
                </v-btn>
              </h4>
            </div>
            <div v-if="!isEndReached">
              <v-card-title v-intersect="onIntersect" class="fs-14px py-1 px-4 secondary--text">
                <i class="fad fa-spin fa-spinner-third mr-2"></i>Fetching more downloads ...
              </v-card-title>
            </div>
          </div>
        </v-scroll-y-transition>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import perms from "../../../plugins/permissions";
import enums from "../../../plugins/enums";
import downloadCenterAPI from "../services/download-center-service.js";
import DownloadStatus from "./DownloadStatus.vue";
import { eventBus } from "../../../main";
import ToastNotificationPermission from "../../Shared/components/ToastNotificationPermission.vue";

export default {
  components: { DownloadStatus },
  data() {
    return {
      perms,
      enums,
      downloads: [],
      downloadCenterPopover: false,
      downloadCenterBadge: false,
      isDownloadsInitiallyLoaded: false,
      isDownloadsLoading: false,
      isIntersecting: false,
      isEndReached: false,
      options: {
        search: null,
        page: 0,
        itemsPerPage: 10,
        sortBy: "createDate",
        sortDesc: true,
      },
      total: 0,
      toastOptions: {
        id: "COCO_NOTIFY_PERM",
        position: "top-center",
        timeout: false,
        closeOnClick: false,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: false,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: false,
        icon: false,
        rtl: false,
        toastClassName: "notification-permission",
      },
    };
  },
  created() {
    eventBus.$on("add-to-download-center", (obj) => {
      this.$log(">>>>>>>>>>>>> add-to-download-center", obj);
      var findDownloadIndex = this.downloads.findIndex((notif) => notif.id == obj.id);
      if (findDownloadIndex === -1) {
        this.downloads.unshift(obj);
      } else {
        this.downloads.splice(findDownloadIndex, 1);
        this.downloads.unshift(obj);
      }
    });
    this.$connection.on("SendTask", (obj) => {
      this.$log("SendTask", obj);
      this.downloadCenterBadge = true;
      var findDownloadIndex = this.downloads.findIndex((notif) => notif.id == obj.id);
      if (findDownloadIndex === -1) {
        this.downloads.unshift(obj);
      } else {
        this.downloads.splice(findDownloadIndex, 1);
        this.downloads.unshift(obj);
      }
      this.$notify(obj, "success", obj.id, true);
      this.browserNotify(
        this.generateNotificationTitle(obj),
        this.generateNotificationText(obj),
        obj
      );
    });
    this.getData();
    this.checkNotificationPermission();
  },
  computed: {},
  methods: {
    checkNotificationPermission() {
      var notification_perm = this.$getFromLocal("COCO_NotificationPerm", false, "default");
      if (Notification.permission == "default" && notification_perm != "denied") {
        this.$toast.info({ component: ToastNotificationPermission }, this.toastOptions);
      }
    },
    generateNotificationTitle(notification) {
      var type = this.getEnumMember(enums.TemplateType, notification.templateType);
      var status = this.getEnumMember(enums.DownloadStatus, notification.status);
      // return `Concordia - ${type.text}'s ${status.text.toLowerCase()}`;
      return `[${status.text}] ${type.text} - Concordia`;
    },
    generateNotificationText(notification) {
      var status = this.getEnumMember(enums.DownloadStatus, notification.status);
      return `${notification.fileName}`;
    },
    browserNotify(notificationTitle, notificationText, data) {
      // Let's check if the browser supports downloads
      if (!("Notification" in window)) {
        this.$log("This browser does not support desktop notification");
      }

      // Let's check whether notification permissions have already been granted
      else if (Notification.permission === "granted") {
        var iconImg = "/img/DNA_Symbol.png";
        if (data.status == enums.DownloadStatus.Succeeded.value)
          iconImg = "/img/DNA_Symbol_R_Success.png";
        else if (data.status == enums.DownloadStatus.Inprogress.value)
          iconImg = "/img/DNA_Symbol_R_InProgress.png";
        else if (data.status == enums.DownloadStatus.Failed.value)
          iconImg = "/img/DNA_Symbol_R_Failed.png";

        var iconImgUrl = window.location.origin + iconImg;
        // If it's okay let's create a notification
        var notif = new Notification(notificationTitle, {
          body: notificationText,
          data: data,
          icon: iconImgUrl,
        });
        notif.onclick = function (evt) {
          window.this.$log("data", evt.target.data);
          var notifObj = evt.target.data;
          if (notifObj != null && notifObj.status == enums.DownloadStatus.Succeeded.value) {
            window.open(notifObj.url, "Download");
            // var downloadFrame = document.createElement("iframe");
            // downloadFrame.setAttribute("src", notifObj.url);
            // downloadFrame.setAttribute("class", "screenReaderText");
            // document.body.appendChild(downloadFrame);
          }
          window.focus();
          this.close();
        };
      }
      // At last, if the user has denied downloads, and you
      // want to be respectful there is no need to bother them any more.
    },
    getTemplateType(notification) {
      if (notification != null) {
        return this.getEnumMember(enums.TemplateType, notification.templateType);
      } else return null;
    },
    getDownloadStatus(notification) {
      if (notification != null) {
        return this.getEnumMember(enums.DownloadStatus, notification.status);
      } else return null;
    },
    refresh() {
      this.options.page = 0;
      this.downloads = [];
      this.isEndReached = false;
      this.isDownloadsInitiallyLoaded = false;
      this.getData();
    },
    getData() {
      this.$log(">>>> getData - this.isEndReached", this.isEndReached);
      if (this.isEndReached || this.isDownloadsLoading) return;
      this.options.page++;
      let optionsToSend = this.cloneDeep(this.options);

      this.isDownloadsLoading = true;
      downloadCenterAPI
        .query(this.$clean(optionsToSend, true))
        .then((resp) => {
          if (!this.isDownloadsInitiallyLoaded) {
            this.isDownloadsInitiallyLoaded = true;
          }
          if (resp.data.items.length < this.options.itemsPerPage) {
            this.isEndReached = true;
          }
          this.$log("resp.data", resp.data, "isEndReached ", this.isEndReached);
          resp.data.items.forEach((element) => {
            if (this.downloads.findIndex((notif) => notif.id == element.id) === -1)
              this.downloads.push(element);
          });
          this.total = resp.data.total;
          this.isDownloadsLoading = false;

          setTimeout(() => {
            this.$log(">>>> setTimeout - this.isIntersecting:", this.isIntersecting);
            if (this.isIntersecting && !this.isEndReached) {
              this.getData();
            }
          }, 500);
        })
        .catch((err) => {
          this.isDownloadsLoading = false;
          // this.$handleError(err);
        });
    },
    onIntersect(entries, observer) {
      // More information about these options
      // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      this.isIntersecting = entries[0].isIntersecting;
      this.$log(">>>> onIntersect - this.isIntersecting:", this.isIntersecting);
      if (this.isIntersecting) {
        this.getData();
      }
    },
  },
  watch: {
    downloadCenterPopover() {
      if (this.downloadCenterPopover) {
        this.downloadCenterBadge = false;
      }
    },
  },
};
</script>

<style lang="scss">
.download-card {
  display: flex;
  flex-direction: column;
  padding: 0.65rem 1rem;
  transition: 0.2s all ease-out;
  border-bottom: 1px solid rgba($shades-black, 0.16);
  align-items: flex-start;

  &:hover {
    background: rgba($shades-black, 0.06);
  }
}

.download-center-menu-content {
  height: 400px;
  max-height: 400px;
  min-height: 400px !important;
  width: 350px;
  max-width: 350px;
  min-width: 350px;
  display: flex;
  flex-direction: column;

  & > .v-card {
    flex: 1 1 auto;
  }
}
</style>
