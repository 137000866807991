import perms from "../../../plugins/permissions";
export default [
  {
    path: "/engineering-firms",
    name: "engineering-firms",
    // TODO: update this line when permissions updated
    meta: {
      layout: "dashboard",
      title: "Engineering Firms",
      permissions: [perms.EngineeringFirms.View],
    },
    component: () => import("./views/EngineeringFirms.vue"),
  },
  {
    path: "/engineering-firms/:id",
    name: "single-engineering-firm",
    meta: { layout: "dashboard", permissions: [perms.EngineeringFirms.View] },
    component: () => import("./views/EngineeringFirms.vue"),
  },
];
