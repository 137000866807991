import perms from "../../../plugins/permissions";
export default [
  {
    path: "/representatives",
    name: "representatives",
    // TODO: update this line when permissions updated
    meta: {
      layout: "dashboard",
      title: "Representatives",
      permissions: [perms.Representatives.View],
    },
    component: () => import("./views/Representatives.vue"),
  },
  {
    path: "/representatives/:id",
    name: "single-representative",
    meta: { layout: "dashboard", permissions: [perms.Representatives.View] },
    component: () => import("./views/Representatives.vue"),
  },
];
