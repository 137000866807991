import FormRules from "./form-rules";
import moment from "moment";
import { isEqual, cloneDeep, trim } from "lodash";
import { has } from "./helpers";
import { stripHtml } from "./filters";
import perms from "./permissions";
import ToastNotification from "../features/Shared/components/ToastNotification.vue";

let toastOptions = {
  position: "top-right",
  timeout: 5000,
  closeOnClick: false,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: false,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: false,
  rtl: false,
  toastClassName: "elevation-4",
};
export default {
  data() {
    return {
      allRules: FormRules,
      $perms: perms,
    };
  },
  methods: {
    cloneDeep,
    isEqual,
    trim,
    $has: has,
    $stripHtml: stripHtml,
    /**
     * Deep diff between two object-likes
     * @param  {Object} fromObject the original object
     * @param  {Object} toObject   the updated object
     * @return {Object}            a new object which represents the diff
     */
    deepDiff(fromObject, toObject) {
      const changes = {};

      const buildPath = (path, obj, key) => (_.isUndefined(path) ? key : `${path}.${key}`);

      const walk = (fromObject, toObject, path) => {
        for (const key of _.keys(fromObject)) {
          const currentPath = buildPath(path, fromObject, key);
          if (!_.has(toObject, key)) {
            changes[currentPath] = { from: _.get(fromObject, key) };
          }
        }

        for (const [key, to] of _.entries(toObject)) {
          const currentPath = buildPath(path, toObject, key);
          if (!_.has(fromObject, key)) {
            changes[currentPath] = { to };
          } else {
            const from = _.get(fromObject, key);
            if (!_.isEqual(from, to)) {
              if (_.isObjectLike(to) && _.isObjectLike(from)) {
                walk(from, to, currentPath);
              } else {
                changes[currentPath] = { from, to };
              }
            }
          }
        }
      };

      walk(fromObject, toObject);

      return changes;
    },
    fixedFloat(val) {
      var fixed = parseFloat(val.toFixed(2));
      return fixed;
    },
    filterByDate(first, second) {
      var res = moment(first).isSame(second);
      return res;
    },
    $clean(obj, cleanEmptyArray = false) {
      var source = this.$clone(obj);
      Object.keys(source).forEach((key) => {
        if (
          (!source[key] && source[key] !== false && source[key] !== 0) ||
          (cleanEmptyArray && Array.isArray(source[key]) && !source[key].length)
        )
          delete source[key];
      });
      return source;
    },
    addToArr(arr, element, onTop = false) {
      // if (arr == null) arr = [];
      if (arr.length == 0) {
        // arr = [element];
        arr.push(element);
      } else {
        if (onTop) arr.unshift(element);
        else this.$set(arr, arr.length, element);
      }
    },
    updateArr(arr, element) {
      var i = arr.indexOf(arr.filter((e) => e.id == element.id)[0]);
      if (arr.length == 0) arr.push({});
      this.$set(arr, i, element);
    },
    updateOrAdd(arr, element, key = "id") {
      const index = arr.findIndex((elm) => elm[key] == element[key]);
      if (index > -1) {
        this.$set(arr, index, element);
      } else {
        this.addToArr(arr, element);
      }
    },
    addUniqueArr(arr, items, key = "id", top = false) {
      items.forEach((elm) => {
        const index = arr.findIndex((elm2) => elm[key] == elm2[key]);
        if (index != -1) {
          arr.splice(index, 1);
        }
        if (top) {
          arr.unshift(elm);
        } else {
          arr.push(elm);
        }
      });
    },
    round: function (num, percesion) {
      if (!percesion) percesion = 2;
      var factor = Math.pow(10, percesion);
      return Math.round(num * factor) / factor;
    },
    $clone: function (thing, opts) {
      var newObject = {};
      var vm = this;
      if (thing instanceof Array) {
        return thing.map(function (i) {
          return vm.$clone(i, opts);
        });
      } else if (thing instanceof Date) {
        return new Date(thing);
      } else if (thing instanceof RegExp) {
        return new RegExp(thing);
      } else if (thing instanceof Function) {
        return opts && opts.newFns ? new Function("return " + thing.toString())() : thing;
      } else if (thing instanceof Object) {
        Object.keys(thing).forEach(function (key) {
          newObject[key] = vm.$clone(thing[key], opts);
        });
        return newObject;
      } else if ([undefined, null].indexOf(thing) > -1) {
        return thing;
      } else {
        if (thing.constructor.name === "Symbol") {
          return Symbol(
            thing
              .toString()
              .replace(/^Symbol\(/, "")
              .slice(0, -1)
          );
        }
        // return _.clone(thing);  // If you must use _ ;)
        return thing.__proto__.constructor(thing);
      }
    },
    getEnumMember: function (enumClass, enumValue) {
      for (var val in enumClass) {
        if (enumClass[val].value == enumValue) return enumClass[val];
      }
      return null;
    },
    generateUniqueId() {
      const dateString = Date.now().toString(36);
      const randomness = Math.random().toString(36).substring(2);
      return dateString + randomness;
    },
    formatDate: function (d) {
      return this.formateDateWithMoment(d, "YYYY-MM-DD");
      try {
        var date = new Date(Date.parse(d));
        const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
        const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
        const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
        return `${da} ${mo} ${ye}`;
      } catch {
        return "unknown";
      }
    },
    getDateObj: function (date) {
      if (!date) return null;
      return moment(date).toDate();
    },
    formateDateWithMoment: function (date, formats) {
      if (!date) return null;
      return moment(date).format(formats);
    },
    $handleError(error, validationErrorHandler) {
      this.$log("> error", error);
      this.$log(">> error.config", error.config);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        this.$log(">>> error.response.status", error.response.status);
        this.$log(">>> error.response.data", error.response.data);
        this.$log(">>> error.response.data", typeof error.response.data);
        this.$log(">>> error.response.headers", error.response.headers);
        if (typeof error.response.data == "string") {
          this.$log(">>> error.response.data", error.response.data);
          this.$dialog.notify.error(error.response.data, {
            position: "top-right",
            timeout: 10 * 1000,
          });
          return;
        }
        let errObj = error.response.data;
        switch (errObj.errorType) {
          case "Validation":
            if (validationErrorHandler) {
              validationErrorHandler(errObj, error);
              return;
            }
            this.$dialog.notify.error(
              this.parseErrorsList(errObj.errors) || "Unknown error occured!",
              {
                position: "top-right",
                timeout: 10 * 1000,
              }
            );
            break;
          case "BadRequest":
          default:
            this.$dialog.notify.error(
              errObj.errors ||
                errObj.message ||
                errObj.Message + "<br />" + errObj.Detail ||
                error.response.data.toString() ||
                "Unknown error occured!",
              {
                position: "top-right",
                timeout: 10 * 1000,
              }
            );
            break;
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        this.$log("error.request", error.request);

        this.$dialog.notify.error("Error receiving a response!", {
          position: "top-right",
          timeout: 10 * 1000,
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        this.$log("Error", error.message);

        this.$dialog.notify.error(error.message || "Unknown error occured!", {
          position: "top-right",
          timeout: 10 * 1000,
        });
      }
    },
    uniqueId() {
      const dateString = Date.now().toString(36);
      const randomness = Math.random().toString(36).substr(2);
      return dateString + randomness;
    },
    parseErrorsList(errors) {
      var list = [];
      for (const err in errors) {
        this.$log(`${err}: ${errors[err]}`);
        list.push(errors[err]);
      }
      return list.join(",");
    },
    $handleError_OLD(error, errorMessage, validationErrorHandler) {
      var errorObj = this.parseError(error);

      //js error
      if (!error.request) {
        if (!errorMessage) errorMessage = "Unknown JS Error";
        else errorMessage = " <div><h4> " + errorMessage + " </h4> <br> JS Error </div> ";

        this.$dialog.notify.error(errorMessage, {
          position: "top-right",
          timeout: 3000,
        });
        return;
      }

      // server error
      if (!errorMessage) errorMessage = "Unknown API Error";
      else errorMessage = " <div><h4> " + errorMessage + " </h4> <br> API Error </div> ";
      switch (errorObj.errorType) {
        case "Validation":
          if (validationErrorHandler) validationErrorHandler(errorObj);
          break;
        case "BadRequest":
        default:
          this.$dialog.notify.error(errorMessage, {
            position: "top-right",
            timeout: 3000,
          });
          break;
      }
    },
    parseError(errorMessage) {
      var errorObj = {
        type: "Unknown",
        message: "Unknown Error!",
      };
      if (!errorMessage) return errorObj;

      try {
        errorObj = JSON.parse(errorMessage);
      } catch (error) {
        return errorObj;
      }
      return errorObj;
    },
    showToast: function (variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant: variant,
        solid: true,
        content,
      });
    },
    $guardChanges() {
      this.$store.dispatch("blockRouter");
    },
    $releaseChanges() {
      this.$store.dispatch("unblockRouter");
    },
    $confirmReleaseChanges() {
      return window.confirm("You have unsaved changes! leave anyway?");
    },
    $hasRoles(roles) {
      if (!roles) return false;
      if (roles.includes("All")) return true;
      var userRoles = this.$store.getters.user.roles;
      if (!userRoles || userRoles.length == 0) return false;
      var role = userRoles[0].name;
      if (roles instanceof Array) return roles.includes(role);
      else return roles === role;
    },
    $getFromLocal(name, parse = false, defaultValue) {
      let item = localStorage.getItem(name);
      if (item) {
        item = parse ? JSON.parse(item) : item;
        return item;
      }
      return defaultValue;
    },
    $setToLocal(name, value, stringify = false) {
      localStorage.setItem(name, stringify ? JSON.stringify(value) : value);
    },
    $backToTop(to = 0, elm = null) {
      if (elm) {
        elm.scrollTo({ top: to, behavior: "smooth" });
      } else {
        window.scrollTo({ top: to, behavior: "smooth" });
      }
    },
    $moveable(selector) {
      let isDown = false;
      let startX;
      let scrollLeft;

      selector.addEventListener("mousedown", (e) => {
        isDown = true;
        selector.classList.add("scrolling");
        startX = e.pageX - selector.offsetLeft;
        scrollLeft = selector.scrollLeft;
      });
      selector.addEventListener("mouseleave", () => {
        isDown = false;
        selector.classList.remove("scrolling");
      });
      selector.addEventListener("mouseup", () => {
        isDown = false;
        selector.classList.remove("scrolling");
      });
      selector.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        const elements = ["INPUT", "TEXTAREA"];
        if (elements.includes(e.target.tagName) || elements.includes(e.srcElement.tagName)) {
          return;
        } else {
          e.preventDefault();
        }
        const x = e.pageX - selector.offsetLeft;
        const walk = (x - startX) * 1; //scroll-fast
        selector.scrollLeft = scrollLeft - walk;
      });
    },
    $getTextByValue(value, arr) {
      const item = arr.find((elm) => elm.value == value);
      if (item) {
        return item.text;
      }
    },
    $log() {
      if (this.isLiveEnv) return;
      var args = Array.prototype.slice.call(arguments);
      console.log.apply(console, args);
    },
    $warn() {
      if (this.isLiveEnv) return;
      var args = Array.prototype.slice.call(arguments);
      console.warn.apply(console, args);
    },
    $error() {
      if (this.isLiveEnv) return;
      var args = Array.prototype.slice.call(arguments);
      console.error.apply(console, args);
    },
    $console() {
      if (this.isLiveEnv)
        return {
          log: function () {},
          warn: function () {},
          error: function () {},
        };
      else
        return {
          log: function () {
            var args = Array.prototype.slice.call(arguments);
            console.log.apply(console, args);
          },
          warn: function () {
            var args = Array.prototype.slice.call(arguments);
            console.warn.apply(console, args);
          },
          error: function () {
            var args = Array.prototype.slice.call(arguments);
            console.error.apply(console, args);
          },
        };
    },
    $notify(notification, type, toastId, replace) {
      //show a toast
      var readyToastOptions = this.cloneDeep(toastOptions);
      if (toastId != null) {
        readyToastOptions.id = toastId;

        if (replace) {
          this.$toast.dismiss(toastId);
        }
      }

      if (type == "info") {
        this.$toast.info(
          {
            component: ToastNotification,
            props: {
              value: notification, // Optional
            },
          },
          readyToastOptions
        );
      } else {
        this.$toast.success(
          {
            component: ToastNotification,
            props: {
              value: notification, // Optional
            },
          },
          readyToastOptions
        );
      }
    },
    findRequiredFieldInFrom(form, preAction) {
      for (let i = 0; i < form.inputs.length; i++) {
        const input = form.inputs[i];
        if (input.hasError) {
          if (typeof preAction === "function") preAction();
          setTimeout(() => {
            input.$el.querySelector("input, textarea").select();
            input.$el.querySelector("input, textarea").scrollIntoView();
          }, 150);
          return true;
        }
      }
      return false;
    },
    deepGet(obj, keys) {
      return keys.reduce((xs, x) => xs?.[x] ?? null, obj);
    },
    deepGetByPath(obj, path) {
      if (path == undefined) return "";
      return this.deepGet(
        obj,
        path
          .replace(/\[([^\[\]]*)\]/g, ".$1.")
          .split(".")
          .filter((t) => t !== "")
      );
    },
    deepGetByPaths(obj, paths) {
      return paths.map((path) =>
        this.deepGet(
          obj,
          path
            .replace(/\[([^\[\]]*)\]/g, ".$1.")
            .split(".")
            .filter((t) => t !== "")
        )
      );
    },
  },
  computed: {
    loggedInUser() {
      return this.$store.getters.user;
    },
    isLocalhostEnv() {
      if (window.location.hostname == "localhost") return true;
      return false;
    },
    isDevEnv() {
      if (window.location.hostname == "concordia-app-dev.azurewebsites.net") return true;
      return false;
    },
    isTestEnv() {
      if (window.location.hostname == "concordia-app-test.azurewebsites.net") return true;
      return false;
    },
    isDemoEnv() {
      if (window.location.hostname == "concordia-app-demo.azurewebsites.net") return true;
      return false;
    },
    isLiveEnv() {
      if (
        window.location.hostname == "concordia-app.azurewebsites.net" ||
        window.location.hostname == "concordia.dangeloconsultants.com"
      )
        return true;
      return false;
    },
  },
};
