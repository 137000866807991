var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user != null && _vm.user.id != null)?_c('div',_vm._g({staticClass:"user-avatar",class:{
    'user-avatar-mini': _vm.mini,
    'open-as-link': _vm.allowOpenUserLink,
    'user-avatar-elevated': _vm.elevated,
  }},_vm.allowOpenUserLink ? { click: _vm.openUserInNewTab } : {}),[_c('v-tooltip',{key:"user-circle-icon",attrs:{"top":!_vm.tooltipBottom,"bottom":_vm.tooltipBottom,"nudge-top":_vm.tooltipBottom ? 5 : -8,"disabled":!_vm.icon || _vm.noTooltip,"z-index":"100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"color":"orange darken-1","overlap":"","value":_vm.user.isSuspended || false},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('i',{staticClass:"fas fa-ban fs-10px secondary--text"})]},proxy:true}],null,true)},[_c('div',_vm._g(_vm._b({staticClass:"user-circle",style:({
            color: _vm.user.isSuspended && _vm.maskSuspendedUsers ? _vm.avatarColor.fg : _vm.avatarColor.fg,
            backgroundColor:
              _vm.user.isSuspended && _vm.maskSuspendedUsers ? _vm.colors.shades.white : _vm.avatarColor.bg,
          })},'div',attrs,false),on),[(_vm.user.avatarUrl || (_vm.user.isSuspended && _vm.maskSuspendedUsers))?_c('img',{staticClass:"user-image",attrs:{"alt":_vm.fullName,"src":_vm.avatar}}):_vm._e(),(!_vm.user.isSuspended || !_vm.maskSuspendedUsers)?_c('span',{staticClass:"user-initials",domProps:{"textContent":_vm._s(_vm.initials)}}):_vm._e(),(_vm.user.isExternalUser)?_c('span',{staticClass:"user-external-icon"},[_c('i',{staticClass:"fas fa-circle white--text"}),_c('i',{staticClass:"fas fa-circle-star light-blue--text text--darken-4"})]):_vm._e()])])]}}],null,false,552966379)},[_c('span',[_vm._v(_vm._s(_vm.fullName))])]),(!_vm.icon)?_c('span',{key:"user-text",staticClass:"user-name-role"},[_c('span',{staticClass:"user-fullName",class:{
        'text--secondary font-italic': _vm.user.isSuspended && !_vm.maskSuspendedUsers,
      },domProps:{"textContent":_vm._s(_vm.fullName)}}),_c('span',{staticClass:"d-flex algin-center",staticStyle:{"margin-top":"0.15rem"}},[(!_vm.hideLabor)?_c('span',{staticClass:"user-labor",class:{
          'text--secondary font-italic': _vm.user.isSuspended && !_vm.maskSuspendedUsers,
        }},[_c('i',{staticClass:"fad fa-wrench-simple fs-10px"}),_vm._v(" "+_vm._s(_vm.getEnumMember(_vm.enums.LABOR_TYPE_LIST, _vm.user.defaultLaborType).text)+" ")]):_vm._e(),(_vm.role && !_vm.hideRoles)?_c('span',{staticClass:"user-role-sep"},[_c('i',{staticClass:"fal fa-slash-back fs-10px fa-flip-vertical mx-1"})]):_vm._e(),(_vm.role && !_vm.hideRoles)?_c('span',{staticClass:"user-role"},[_c('i',{staticClass:"fad fa-key fs-10px"}),_vm._v(" "+_vm._s(_vm.role)+" ")]):_vm._e()])]):_vm._e()],1):(_vm.user == null && _vm.showNoUser)?_c('div',{staticClass:"user-avatar",class:{ 'user-avatar-mini': _vm.mini }},[_c('div',{staticClass:"user-circle",style:({
      color: _vm.noUserAvatarColor.fg,
      backgroundColor: _vm.noUserAvatarColor.bg,
    })},[_c('span',{staticClass:"user-initials"},[_c('i',{class:_vm.noUserIcon == null || _vm.noUserIcon == '' ? 'fad fa-user-alt-slash' : _vm.noUserIcon})])]),(!_vm.icon)?_c('span',{staticClass:"user-name-role"},[_c('span',{staticClass:"user-fullName text--secondary",domProps:{"textContent":_vm._s(_vm.noUserText == null || _vm.noUserText == '' ? 'No User' : _vm.noUserText)}})]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }