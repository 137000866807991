import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import axios from "axios";
import VueAxios from "vue-axios";

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
(axios.withCredentials = false), (axios.defaults.withCredentials = false), Vue.use(VueAxios, axios);

axios.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    if (error.response && error.response.status === 401) {
      var data = error.response.data;
      if (data.ErrorType == "Relogin") {
        store.dispatch("logOut");
        setTimeout(() => {
          window.location.href = "/login?r=permissions-updated";
        }, 100);
        return;
      }
    }
    throw error;
  }
);

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  // this.$log("axios.interceptors => token:", token);
  config.headers["Authorization"] = token;
  return config;
});

export default axios;
