<template>
  <div class="user-profile" v-if="user !== null">
    <v-btn icon small elevation="0" v-if="false">
      <v-badge v-if="!hideMessages" bordered color="success" dot>
        <i class="fal fa-envelope"></i>
      </v-badge>
    </v-btn>
    <div class="ml-1">
      <download-center-menu v-if="!hideNotifications"></download-center-menu>
    </div>
    <user-avatar
      :user="user"
      :icon="$vuetify.breakpoint.mobile || mini"
      disable-open-link
    ></user-avatar>
    <v-menu dense offset-y>
      <template v-slot:activator="{ attrs, on }">
        <v-btn small icon elevation="0" class="mr-1" v-bind="attrs" v-on="on">
          <i class="far fa-angle-down"></i>
        </v-btn>
      </template>
      <v-list class="user-profile-menu-list">
        <v-list-item link href="/profile" v-if="false">
          <v-list-item-icon class="mr-2 justify-center">
            <v-icon small color="secondary">fal fa-user</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title color="secondary">Profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="toggleDarkMode" v-if="false">
          <v-list-item-icon class="mr-2 justify-center">
            <v-icon small color="secondary">fal {{ darkMode ? "fa-moon-stars" : "fa-sun" }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title color="secondary"> {{ switchLabel }} Mode </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          v-if="isLocalhostEnv || isDevEnv || isTestEnv"
          @click="permsDialog = true"
        >
          <v-list-item-icon class="mr-2 justify-center">
            <v-icon small color="secondary">fal fa-lock-alt</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title color="secondary"> List Permissions </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$has(perms.PersonalSettings.View)" @click="openPersonalSettingsSlideout">
          <v-list-item-icon class="mr-2 justify-center">
            <v-icon small color="secondary">fal fa-user-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title color="secondary"> Personal Settings </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link href="/logout">
          <v-list-item-icon class="mr-2 justify-center">
            <v-icon small color="secondary">fal fa-sign-out</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title color="secondary">Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- update view Modal -->
    <v-dialog v-model="permsDialog" max-width="420px" scrollable style="z-index: 99999999">
      <v-card>
        <v-card-title class="font-weight-bold" small>
          {{ user.firstName }} {{ user.lastName }}'s Permissions "{{ userPerms.length }}"!
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container class="py-0">
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item v-for="(perm, i) in userPerms" :key="i" :ripple="false">
                  <v-list-item-content>
                    <v-list-item-title>{{ perm.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="permsDialog = false" text> <i class="fal fa-xmark mr-2"></i> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <personal-settings ref="personalSettingsSlideout"></personal-settings>
  </div>
</template>

<script>
import UserAvatar from "../../Shared/components/UserAvatar.vue";
import store from "../../../store";
import enums from "../../../plugins/enums";
import perms from "../../../plugins/permissions";
import personalSettingsService from "../../PersonalSettings/services/personal-settings-service";
import PersonalSettings from "../../PersonalSettings/components/PersonalSettings.vue";
import DownloadCenterMenu from "../../DownloadCenter/components/DownloadCenterMenu.vue";

export default {
  components: { UserAvatar, PersonalSettings, DownloadCenterMenu },
  data() {
    return {
      perms,
      enums,
      darkMode: false,
      permsDialog: false,
    };
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    role: {
      type: String,
      default: null,
    },
    mini: {
      type: Boolean,
      default: null,
    },
    hideNotifications: {
      type: Boolean,
      default: false,
    },
    messages: {
      type: Array,
      default: null,
    },
    hideMessages: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    switchLabel: function () {
      return this.darkMode ? "Dark" : "Light";
    },
    userPerms() {
      if (
        store.getters.user &&
        store.getters.user.roles[0] &&
        store.getters.user.roles[0].permissions
      ) {
        return this.cloneDeep(store.getters.user.roles[0].permissions).sort((a, b) => {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
      }
      return [];
    },
  },
  created() {},
  mounted() {
    this.loadPersonalSettings();
    window.addEventListener("storage", this.onStorageUpdate);
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.onStorageUpdate);
  },
  methods: {
    onStorageUpdate(event) {
      if (event.key === "personalSettings") {
        this.$log("onStorageUpdate PersonalSettings");
        this.$store.dispatch("getPersonalSettings");
      }
    },
    openPersonalSettingsSlideout() {
      this.$refs.personalSettingsSlideout.open();
    },
    loadPersonalSettings() {
      this.personalSettingsLoading = true;
      personalSettingsService
        .get()
        .then((resp) => {
          this.$log("set PersonalSettings", resp.data);
          this.$store.dispatch("setPersonalSettings", this.cloneDeep(resp.data));
          this.$log("get PersonalSettings", this.$store.getters.personalSettings);
          this.personalSettingsLoading = false;
        })
        .catch((e) => {
          this.$log(e);
          this.personalSettingsLoading = false;
        });
    },
    toggleDarkMode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.darkMode = !this.darkMode;
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.user-profile {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: default;

  .v-btn.v-btn--icon {
    font-size: 16px;
  }
}
.user-profile-menu-list {
  .v-list-item {
    height: 32px;
    min-height: 32px;
  }
  .v-list-item__icon {
    align-self: center;
  }
}
</style>
