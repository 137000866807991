var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rich-text-editor-wrapper"},[_c('div',{staticClass:"d-flex flex-row align-center justify-space-between mb-2"},[_c('span',{staticClass:"d-flex flex-row flex-wrap align-center",staticStyle:{"gap":"0.5rem"}},[(_vm.showLabel)?_c('label',{staticClass:"input-label",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),(_vm.subtitle != null && _vm.subtitle.trim() != '')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.subtitle)}}):_vm._t("subtitle"),_vm._t("label-append")],2),(_vm.allowExpand && !_vm.readonly)?_c('v-btn',{attrs:{"outlined":"","small":!_vm.smallExpandButton,"x-small":_vm.smallExpandButton,"color":"secondary"},on:{"click":function($event){_vm.modals.expandEditor.active = true}}},[_c('i',{staticClass:"fal fa-expand-arrows me-2 fs-12px"}),_c('span',{staticClass:"fs-12px"},[_vm._v(" Expand Editor ")])]):_vm._e()],1),(!_vm.readonly)?_c('tiptap-vuetify',{ref:"tiptapVuetify",attrs:{"toolbar-attributes":{
      color: _vm.$vuetify.theme.dark ? 'secondary' : 'blue-grey lighten-5',
      dark: _vm.$vuetify.theme.dark,
    },"extensions":_vm.extensions,"minHeight":"125px","maxHeight":_vm.maxHeight || '',"placeholder":"Write something ..."},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_c('div',{staticClass:"tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly",domProps:{"innerHTML":_vm._s(
      _vm.selected != null && _vm.$stripHtml(_vm.selected) != ''
        ? _vm.selected
        : "<i class='fad fa-empty-set opacity-40'></i>"
    )}}),_c('v-dialog',{staticStyle:{"z-index":"99999999"},attrs:{"max-width":"64vw","scrollable":""},model:{value:(_vm.modals.expandEditor.active),callback:function ($$v) {_vm.$set(_vm.modals.expandEditor, "active", $$v)},expression:"modals.expandEditor.active"}},[_c('v-card',[_c('v-card-title',{staticClass:"font-weight-bold py-2 px-4",attrs:{"small":""}},[_c('i',{staticClass:"fad fa-pen-square mr-2"}),_vm._v("Update "),_c('div',{staticClass:"ml-2",domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.modals.expandEditor.active = false}}},[_c('i',{staticClass:"far fa-xmark fs-16px"})])],1),_c('v-divider'),_c('v-card-text',{staticClass:"px-0 pb-0"},[_c('v-container',{staticClass:"pa-5 inset-shadow",staticStyle:{"background":"#eceff1"},attrs:{"fluid":""}},[_c('v-form',{ref:"expandSysDescEditorForm",model:{value:(_vm.modals.expandEditor.valid),callback:function ($$v) {_vm.$set(_vm.modals.expandEditor, "valid", $$v)},expression:"modals.expandEditor.valid"}},[_c('v-row',{staticClass:"my-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"sm":"12"}},[(!_vm.readonly)?_c('tiptap-vuetify',{attrs:{"toolbar-attributes":{
                    color: _vm.$vuetify.theme.dark ? 'secondary' : 'blue-grey lighten-5',
                    dark: _vm.$vuetify.theme.dark,
                  },"extensions":_vm.extensions,"minHeight":"54vh","placeholder":"Write something ..."},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_c('div',{staticClass:"tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly pa-0",domProps:{"innerHTML":_vm._s(
                    _vm.selected != null && _vm.selected != ''
                      ? _vm.selected
                      : "<i class='fad fa-empty-set opacity-40'></i>"
                  )}}),_c('input',{staticStyle:{"display":"none"},attrs:{"type":"text","disabled":"","readonly":""}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.modals.expandEditor.active = false}}},[_c('i',{staticClass:"fad fa-compress-arrows-alt mr-2"}),_vm._v(" Collapse Editor ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }