<template>
  <v-chip
    v-if="status != null && downloadStatusObj != null"
    class="download-status px-1"
    x-small
    outlined
    :color="downloadStatusObj.color || 'secondary'"
  >
    <i class="fas mr-1" :class="downloadStatusObj.iconAlt"></i>
    <span class="font-weight-bold">{{ downloadStatusObj.text }}</span>
  </v-chip>
</template>

<script>
import enums from "../../../plugins/enums";
export default {
  name: "download-status",
  data() {
    return {
      downloadStatuses: enums.DownloadStatus,
      downloadStatusObj: null,
    };
  },
  props: {
    status: {
      type: Number,
      default: null,
    },
  },
  mounted() {
    this.updateDownloadStatusObj();
  },
  computed: {},
  methods: {
    updateDownloadStatusObj() {
      if (this.status != null)
        this.downloadStatusObj = this.getEnumMember(this.downloadStatuses, this.status);
    },
  },
  watch: {
    status: {
      handler() {
        this.updateDownloadStatusObj();
      },
    },
  },
};
</script>

<style lang="scss"></style>
