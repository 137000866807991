import perms from "../../plugins/permissions";
export default [
  {
    path: "/download-center",
    name: "download-center",
    meta: {
      layout: "dashboard",
      title: "DownloadCenter",
      permissions: [perms.DownloadCenter.View],
    },
    component: () => import("./views/DownloadCenter.vue"),
  },
  {
    path: "/manage-download-center",
    name: "manage-download-center",
    meta: {
      layout: "dashboard",
      title: "Manage Download Center",
      permissions: [perms.DownloadCenter.Manage],
    },
    component: () => import("./views/ManageDownloadCenter.vue"),
  },
];
